
.stateBG{
  background-image: url("../../Assets/image/background/states-bg.png");
}

.stateComponents{
  --state-bg-color: #123;
  --state-bg-image: url("/");
  @apply aspect-square rounded-3xl max-w-[240.11px] w-full flex flex-col p-4 justify-center gap-2 text-white relative z-[2];
  background-color: var(--state-bg-color);
  &::before{

    z-index: -1;
    content: " ";
    position: absolute;
    left: 50%;
    top:50%;
    width: 90%;
    height: 90%;
    background-image: var(--state-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translate(-50%,-50%);
  }
}
