@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// :root {
//   font-family: "Noto Sans", Times, serif;
//   font-size: 16px;
// }
:root {
  font-family: "Noto Sans", Times, serif;
  font-size: var(--font-size, 16px); /* Default to 16px if not set */
}


@import "./styles/typography.scss";
/* Custom Styles */

/* Optional body background color if needed */
// body {
//   @apply bg-[#ffffff];
// }
body {
  @apply bg-[#ffffff];
  font-size: var(--font-size, 16px); /* Apply the CSS variable */
}


.carousel-responsive {
  @apply max-w-[350px] sm:max-w-[400px] md:max-w-[90svw] lg:max-w-[80svw] mx-auto w-full;
}

.section-padding {
  @apply py-4 md:py-12;
}

.section-margin {
  @apply mt-6 md:mt-8 lg:mt-12;
}

.theme-box-shadow {
  @apply shadow-[0_0_9px_0_rgba(0,0,0,0.25)];
}

.versioning-css {
  @apply fixed z-[10000] bottom-0 right-0;
}

.vertical-write-mode {
  writing-mode: vertical-lr;

}

.card-isolate {
  isolation: isolate;
}

.card-gradient{
  --start-color: #AE4BFD;
  --end-color: #9109FE;
  background: linear-gradient(103.83deg, var(--start-color) 14.91%, var(--end-color) 79.89%);

}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.swal2-confirm.swal-custom-btn {
  background-color: black !important;
  color: white !important;
  border: none !important;
  box-shadow: none !important;
}

.swal-custom-title {
  color: black !important; 
  font-size: 1.5rem !important;
  font-weight: bold;
}

/* Popup background and icon color */
.swal-custom-popup {
  background-color: #f5f5f5 !important; 
  border-radius: 8px !important;
}

.swal2-warning .swal2-icon-content {
  color: rgb(255 170 23 / var(--tw-bg-opacity)) !important; 
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}
