$theme-primary-color: #FFAA17;

.react-select {
  &__container {
    // Add any styles needed here
  }

  .dark &__control {
    &:hover {
      border-color: $theme-primary-color !important;
    }

    &:focus {
      outline-color: $theme-primary-color !important;
    }

    &:focus-visible {
      outline-color: $theme-primary-color !important;
    }

    background-color: #1b1b29 !important;

    &--is-disabled {
      border-color: theme('colors.gray.500') !important; // Adjust this if using Tailwind with SCSS
    }
  }

  &__menu {
    z-index: 10000 !important;
  }

  .dark &__menu-list {
    background-color: #1b1b29 !important;// Replaced Tailwind with raw color for consistency
  }

  &__option {
    background-color: transparent !important;
  }

  &__input-container {
    color: theme('colors.gray.500') !important; // Adjust as needed
  }

  .dark &__input-container {
    color: theme('colors.gray.100') !important; // Adjust as needed
  }

  &__option--is-selected {
    color: $theme-primary-color !important;
    background-color: theme('colors.gray.100') !important; // Adjust as needed
  }

  .dark &__option--is-selected {
    color: theme('colors.blue.400') !important;
    background-color: theme('colors.gray.700') !important; // Adjust as needed
  }

  &__option--is-focused {
    background-color: $theme-primary-color !important;
    color: theme('colors.gray.100') !important; // Adjust as needed
  }

  .dark &__option--is-focused {
    background-color: $theme-primary-color !important;
    color: theme('colors.gray.100') !important; // Adjust as needed
  }

  .dark &__single-value {
    color: theme('colors.gray.300') !important;

    &--is-disabled {
      color: theme('colors.gray.500') !important; // Adjust as needed
    }
  }

  &__multi-value__remove {
    > svg {
      fill: black !important;
    }

    &:hover {
      > svg {
        fill: red !important;
      }
    }
  }
}
